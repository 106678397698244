<template>
  <svg
    fill="none"
    height="10"
    viewBox="0 0 9 10"
    width="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="4.5"
      cy="5"
      fill="none"
      r="3.75"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <circle
      cx="4.5"
      cy="5"
      fill="currentColor"
      r="1"
      stroke="currentColor"
    />
  </svg>
</template>
