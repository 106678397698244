import { OrderStatus } from '~api/consts'

import type { OrderInfoStatus } from '~types/clientStore'

type orderInfoComposable = {
  checkIfStatusUnsuccessful: (status: OrderInfoStatus) => boolean
  checkIfStatusSuccessful: (status: OrderInfoStatus) => boolean
  checkIfStatusCancelled: (status: OrderInfoStatus) => boolean
}
export default function useOrderInfo(): orderInfoComposable {
  const checkIfStatusSuccessful = (status: OrderInfoStatus): boolean =>
    status.Status === OrderStatus.R_Delivered ||
    status.Status === OrderStatus.R_Ready ||
    status.Status === OrderStatus.R_Close ||
    status.Status === OrderStatus.R_Executed ||
    status.Status === OrderStatus.Finished

  const checkIfStatusUnsuccessful = (status: OrderInfoStatus): boolean =>
    status.Status === OrderStatus.Deleted ||
    status.Status === OrderStatus.NotFoundInDB ||
    status.Status === OrderStatus.DeletedByGuest ||
    status.Status === OrderStatus.DeletedAutomatically ||
    status.Status === OrderStatus.ErrorSavingToBonusSystem ||
    status.Status === OrderStatus.NotEnoughPointsInBonusSystem

  const checkIfStatusCancelled = (status: OrderInfoStatus): boolean =>
    status.Status === OrderStatus.Double ||
    status.Status === OrderStatus.Test ||
    status.Status === OrderStatus.Cancelled

  return {
    checkIfStatusSuccessful,
    checkIfStatusUnsuccessful,
    checkIfStatusCancelled
  }
}
